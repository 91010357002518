<template>
	<section>
		<b-card-countdown
			stage="invitación"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-card>
			<app-collapse type="margin">
				<app-collapse-item
					title="DATOS GENERALES"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col
							cols="12"
							class="my-1"
						>
							<b-alert
								variant="info"
								show
							>
								<h6 class="alert-heading">
									<feather-icon
										icon="InfoIcon"
										class="mr-50"
									/>
									<span
										class="small"
									>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
								</h6>
							</b-alert>
						</b-col>

						<!-- TENDER TITLE -->
						<b-col
							cols="12"
							class="mb-1"
						>
							<label for="name">
								<strong>OBJETO DEL CONTRATO</strong>
							</label>
							<b-form-input
								id="name"
								placeholder="LICITACION DE PRUEBA"
								:value="tenderData.title"
								disabled
							/>
						</b-col>

						<!-- CONTRACT TYPE -->
						<b-col
							cols="12"
							md="7"
							lg="8"
							class="mb-1"
						>
							<label for="contractType">
								<strong>TIPO DE CONTRATO</strong>
							</label>
							<b-form-input
								id="contractType"
								:value="tenderData.contract_name"
								placeholder="LOCACIÓN DE SERVICIOS"
								disabled
							/>
						</b-col>

						<!-- LOCATION -->
						<b-col
							cols="12"
							md="5"
							lg="4"
							class="mb-1"
						>
							<label for="location">
								<strong>EJECUCIÓN DE SERVICIO</strong>
							</label>
							<v-select
								id="location"
								:value="tenderData.locations"
								:options="optionsLocation"
								label="name"
								multiple
								disabled
								readonly
							/>
						</b-col>

						<!-- DESCRIPTION -->
						<b-col cols="12">
							<label for="description">
								<strong>DESCRIPCIÓN</strong>
							</label>
							<b-form-textarea
								id="description"
								:value="tenderData.description"
								placeholder="detalle"
								disabled
							></b-form-textarea>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="INVITACIÓN"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col>
							<h6>
								Estimado
								<strong>{{ bidderData.name }}</strong>,
							</h6>
							<h6>{{ tenderData.invitation_message }}</h6>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="CRONOGRAMA"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col cols="6">
							<b-table
								class="table-sm"
								ref="refScheduleTable"
								responsive
								:fields="fields"
								:items="tenderData.schedules"
								bordered
							>
								<template #cell(actual_start_date)="data">
									<div
										class="text-nowrap"
									>{{ data.item.actual_start_date ? moment(data.item.actual_start_date).format("DD-MM-YYYY H:mm") : 'DD-MM-YYYY' }}</div>
								</template>
								<template #cell(actual_end_date)="data">
									<div
										class="text-nowrap"
									>{{ data.item.actual_end_date ? moment(data.item.actual_end_date).format("DD-MM-YYYY H:mm") : 'DD-MM-YYYY' }}</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</app-collapse-item>
				<app-collapse-item
					title="DOCUMENTOS"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col cols="6">
							<label>
								<strong>DOCUMENTOS GENERALES</strong>
							</label>
							<b-button-upload-file
								:tender-id="tenderData.id"
								:tender-props="{ isGeneral: true, isFromInvitation: true }"
								:button-props="{ icon: 'PaperclipIcon', text: 'VER DOCUMENTOS GENERALES', block : true }"
							/>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="DECISIÓN"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col
							cols="12"
							md="6"
						>
							<label for="state">
								<strong>RESPUESTA</strong>
							</label>
							<v-select
								id="state"
								v-model="responseSelected"
								:options="options"
								placeholder="Seleccione"
								:disabled="isDisabled || stageClosed"
								:readonly="isDisabled || stageClosed"
							/>
						</b-col>
					</b-row>
				</app-collapse-item>
			</app-collapse>

			<b-row>
				<b-col class="mt-3">
					<b-button-loading
						v-if="!isHidden"
						text="ENVIAR RESPUESTA"
						:block="true"
						size="lg"
						:disabled="isDisabled"
						:processing="processing"
						@process-action="sendResponse"
					/>
				</b-col>
			</b-row>
		</b-card>
	</section>
</template>

<script>
import { ref, onBeforeMount } from "@vue/composition-api"
import { Stages, CompetitionStates } from "@core/utils/data"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import useNotifications from "@notifications"
import VueSweetalert2 from "vue-sweetalert2"
import vSelect from "vue-select"
import moment from "moment"
import store from "@/store"
import router from "@/router"
import axios from "@axios"
import Vue from "vue"

Vue.use(VueSweetalert2)

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		BButtonUploadFile,
		BButtonLoading,
		BCardCountdown,

		vSelect
	},
	data() {
		return {
			moment
		}
	},
	props: {
		invitationData: {
			type: Object,
			required: true
		},
		tenderData: {
			type: Object,
			required: true
		},
		bidderData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		const { swalNotification, toastNotification } = useNotifications()

		const invitationData = ref(props.invitationData)
		const scheduleData = ref(props.tenderData.schedules)
		const tenderId = ref(props.tenderData.id)
		const processing = ref(false)
		const optionsLocation = ref([])
		const fields = ref([])
		const isDisabled = ref(false)
		const stageClosed = ref(false)
		const isHidden = ref(false)
		const deadline = ref(null)
		const startDate = ref(null)
		const responseSelected = ref(null)
		const options = ref([
			{ label: "ACEPTADA", value: 1 },
			{ label: "RECHAZADA", value: 0 }
		])

		onBeforeMount(async () => {
			setStageDataTable()
			await getSelectFilters()
		})

		if (
			invitationData.value.competition.competition_state_id !==
			CompetitionStates.INVITACION_ENVIADA
		) {
			stageClosed.value = true
		}

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/locations")
			optionsLocation.value = [...response.data.locations]
		}

		const setStageDataTable = () => {
			const styleColumns = { thClass: "text-center", tdClass: "text-center" }

			fields.value = [
				{
					key: "stage_name",
					label: "Etapa",
					thClass: styleColumns.thClass
				},
				{ key: "actual_start_date", label: "Fecha Inicial", ...styleColumns },
				{ key: "actual_end_date", label: "Fecha Límite", ...styleColumns }
			]

			const result = scheduleData.value.find(
				(item) => parseInt(item.stage_id) === Stages.INVITACION
			)
			startDate.value = result.actual_start_date || null
			deadline.value = result.actual_end_date || null

			const optionSelected = options.value.find(
				(item) => item.value === invitationData.value.answer
			)
			responseSelected.value = optionSelected
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (
				invitationData.value.competition.competition_state_id !==
				CompetitionStates.INVITACION_ENVIADA
			) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		const sendResponse = () => {
			if (!responseSelected.value) {
				const message = "Debe seleccionar una respuesta"
				toastNotification(message, false)
				return
			}

			if (isHidden.value) {
				const message = "No puede realizar esta operación"
				toastNotification(message, false)
				router.push({ name: "competitions-list" })
				return
			}

			processing.value = true

			Vue.swal({
				title: "¿Está seguro(a) de su decisión?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, enviar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					const payload = {
						tender_id: tenderId.value,
						competition_id: invitationData.value.competition.id,
						answer: responseSelected.value.value
					}
					store
						.dispatch("app-competition/sendInvitationResponse", {
							id: invitationData.value.id,
							payload
						})
						.then((response) => {
							swalNotification(response.data.message)
							router.push({ name: "competitions-list" })
						})
						.catch((error) => {
							const message =
								error.response.data.message ||
								"Ocurrió un error al enviar respuesta"
							swalNotification(message, false)
						})
						.finally(() => {
							processing.value = false
						})
				} else {
					processing.value = false
				}
			})
		}

		return {
			// REFS
			fields,
			options,
			optionsLocation,
			responseSelected,
			deadline,
			startDate,
			isDisabled,
			stageClosed,
			isHidden,
			processing,

			// METHODS
			handleTimeFinished,
			handleNotYetStarted,
			sendResponse
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>