
<template>
	<component :is="'div'">
		<b-tabs
			pills
			v-if="invitationData"
		>
			<b-tab name="info">
				<template #title>
					<feather-icon
						icon="BookmarkIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Invitación</span>
				</template>

				<competitions-tab-invitation
					class="mt-2 pt-75"
					:invitation-data="invitationData"
					:tender-data="invitationData.tender"
					:bidder-data="invitationData.bidder"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import CompetitionsTabInvitation from "./CompetitionsTabInvitation.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import useNotifications from "@notifications"
import competitionStoreModule from "../competitionStoreModule"
import router from "@/router"
import store from "@/store"

export default {
	components: {
		CompetitionsTabInvitation
	},
	setup() {
		const { toastNotification } = useNotifications()
		const invitationData = ref(null)

		const COMPETITION_APP_STORE_MODULE_NAME = "app-competition"
		// REGISTER MODULE
		if (!store.hasModule(COMPETITION_APP_STORE_MODULE_NAME))
			store.registerModule(
				COMPETITION_APP_STORE_MODULE_NAME,
				competitionStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(COMPETITION_APP_STORE_MODULE_NAME))
				store.unregisterModule(COMPETITION_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-competition/fetchInvitation", {
				id: router.currentRoute.params.invitation_id
			})
			.then((response) => {
				invitationData.value = response.data.invitation
			})
			.catch((error) => {
				if (error.response.status === 404) {
					invitationData.value = undefined
				}
				toastNotification(error.response.data.message, false)
				router.push({ name: "competitions-list" })
			})

		return {
			invitationData
		}
	}
}
</script>